.pull-requests-header-div {
  margin-top: 100px;
}

.pull-requests-header {
  font-size: 40px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.pull-request-body-div {
  text-align: center;
  width: 100%;
}

@media (max-width: 1380px) {
  .pull-requests-header {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .pull-requests-header-div {
    margin-top: 40px;
  }

  .pull-requests-header {
    font-size: 25px;
  }
}
